import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Button from 'uikit/Button';
import HelpTooltip from 'uikit/HelpTooltip';
import Icon from 'uikit/Icon';
import Switch from 'uikit/Switch';

import styles from './CookieConsent.module.scss';

const cx = classNames.bind(styles);

const CookieConsent = ({
  handleAcceptAll,
  handleAcceptEssential,
  handleBack,
  handleCustomizationSave,
  handleCustomize,
  hasCustomized,
  t,
}) => (
  <CSSTransition
    classNames={{
      exit: cx('container-exit'),
      exitActive: cx('container-exit-active'),
    }}
    in
    mountOnEnter
    timeout={250}
    unmountOnExit
  >
    <aside className={cx('container')}>
      <div className={cx('icon-container')}>
        <Icon className={cx('icon')} name="cookie-bite" />
      </div>
      <p className={cx('title')}>
        {t.titlePart1}
        <a href="https://www.wooflash.com/fr/politique-de-confidentialite">
          cookies
        </a>
        {t.titlePart2}
      </p>
      {hasCustomized ? (
        <Formik
          enableReinitialize
          initialValues={{
            cookieConsentBasic: true,
            cookieConsentFunctionality: false,
            cookieConsentPerformance: false,
            cookieConsentTracking: false,
          }}
          onSubmit={handleCustomizationSave}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={cx('customisationWrapper')}>
                <Switch
                  className={cx('switch')}
                  isDisabled
                  label={
                    <>
                      {t.essentialCookies}
                      <HelpTooltip
                        className={cx('tooltip')}
                        tooltip={t.essentialExplanation}
                      />
                    </>
                  }
                  name="cookieConsentBasic"
                />
                <Switch
                  className={cx('switch')}
                  label={
                    <>
                      {t.functionalityCookies}
                      <HelpTooltip
                        className={cx('tooltip')}
                        tooltip={t.functionalityExplanation}
                      />
                    </>
                  }
                  name="cookieConsentFunctionality"
                />
                <Switch
                  className={cx('switch')}
                  label={
                    <>
                      {t.performanceCookies}
                      <HelpTooltip
                        className={cx('tooltip')}
                        tooltip={t.performanceExplanation}
                      />
                    </>
                  }
                  name="cookieConsentPerformance"
                />
                <Switch
                  className={cx('switch')}
                  label={
                    <>
                      {t.trackingCookies}
                      <HelpTooltip
                        className={cx('tooltip')}
                        tooltip={t.trackingExplanation}
                      />
                    </>
                  }
                  name="cookieConsentTracking"
                />
                <div className={cx('actions')}>
                  <Button
                    className={cx('action')}
                    onClick={handleBack}
                    size="large"
                    type="button"
                  >
                    {t.back}
                  </Button>
                  <Button
                    className={cx('action')}
                    intent="primary"
                    isLoading={isSubmitting}
                    size="large"
                    type="submit"
                  >
                    {t.confirm}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={cx('main-actions')}>
          <Button onClick={handleAcceptAll}>{t.acceptAll}</Button>
          <Button className={cx('main-action')} onClick={handleAcceptEssential}>
            {t.rejectAll}
          </Button>
          <Button className={cx('main-action')} onClick={handleCustomize}>
            {t.customize}
          </Button>
        </div>
      )}
    </aside>
  </CSSTransition>
);

CookieConsent.propTypes = {
  handleAcceptAll: PropTypes.func.isRequired,
  handleAcceptEssential: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleCustomizationSave: PropTypes.func.isRequired,
  handleCustomize: PropTypes.func.isRequired,
  hasCustomized: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default CookieConsent;
