import classNames from 'classnames/bind';
import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';

import Icon from 'uikit/Icon';

import styles from './Notifications.module.scss';

const cx = classNames.bind(styles);

// eslint-disable-next-line react/prop-types
const Close = ({ closeToast }) => (
  <button className={cx('close')} onClick={closeToast} type="button">
    <i className="fal fa-times" />
  </button>
);

// eslint-disable-next-line react/prop-types
const Toast = ({ message, type }) => (
  <div className={cx('container', `type-${type}`)}>
    <span className={cx('icon')}>
      <Icon name="bell" />
    </span>
    <p className={cx('message')}>{message}</p>
  </div>
);

export const NotificationsContainer = () => (
  <ToastContainer
    autoClose={5000}
    closeButton={<Close />}
    closeOnClick
    draggable
    hideProgressBar
    newestOnTop
    pauseOnHover
    pauseOnVisibilityChange={false}
    position="top-right"
    role="alert"
    rtl={false}
    transition={Slide}
  />
);

export function notify(type, message) {
  toast(<Toast message={message} type={type} />);
}
