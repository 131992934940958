import Tippy from '@tippyjs/react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import stylesCSS from './Menu.module.scss';

const cx = classNames.bind(stylesCSS);

const Menu = ({ children, className, placement, toggle }) => (
  <div className={cx('container', className)}>
    <Tippy
      content={
        <div className={cx('menu')} role="menu">
          {children}
        </div>
      }
      duration={0}
      interactive
      maxWidth="none"
      placement={placement}
      plugins={[
        {
          name: 'hideOnClick',
          defaultValue: true,
          fn(instance) {
            return {
              onCreate() {
                instance.popper.addEventListener('click', () => {
                  instance.hide();
                });
              },
            };
          },
        },
      ]}
      role="menu"
      trigger="click focus"
    >
      {toggle}
    </Tippy>
  </div>
);

Menu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  toggle: PropTypes.node.isRequired,
};

Menu.defaultProps = {
  className: undefined,
  placement: 'bottom',
};

export default Menu;
