import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import storeJS from 'store';

import CookieConsent from './CookieConsent';

const CookieConsentContainer = ({ isGAR, ...props }) => {
  const [cookieConsent, setCookieConsent] = useState({
    cookieConsentBasic: storeJS.get('cookieConsentBasic') || false,
    cookieConsentFunctionality:
      storeJS.get('cookieConsentFunctionality') || false,
    cookieConsentPerformance: storeJS.get('cookieConsentPerformance') || false,
    cookieConsentTracking: storeJS.get('cookieConsentTracking') || false,
  });
  useEffect(() => {
    if (!isGAR && cookieConsent.cookieConsentBasic) {
      (function gtm(w, d, s, l, i) {
        // eslint-disable-next-line no-param-reassign
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        w[l].push({
          cookieFunctionality: cookieConsent.cookieConsentFunctionality,
        });
        w[l].push({
          cookiePerformance: cookieConsent.cookieConsentPerformance,
        });
        w[l].push({ cookieTracking: cookieConsent.cookieConsentTracking });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', process.env.GATSBY_GTM_ID);
    }
  }, [cookieConsent, isGAR]);
  const handleCookieConsent = useCallback((values) => {
    storeJS.set('cookieConsentBasic', true);
    storeJS.set(
      'cookieConsentFunctionality',
      values.cookieConsentFunctionality,
    );
    storeJS.set('cookieConsentPerformance', values.cookieConsentPerformance);
    storeJS.set('cookieConsentTracking', values.cookieConsentTracking);
    setCookieConsent({
      cookieConsentBasic: true,
      cookieConsentFunctionality: values.cookieConsentFunctionality,
      cookieConsentPerformance: values.cookieConsentPerformance,
      cookieConsentTracking: values.cookieConsentTracking,
    });
  }, []);
  const handleAcceptAll = useCallback(() => {
    handleCookieConsent({
      cookieConsentFunctionality: true,
      cookieConsentPerformance: true,
      cookieConsentTracking: true,
    });
  }, [handleCookieConsent]);
  const handleAcceptEssential = useCallback(() => {
    handleCookieConsent({
      cookieConsentFunctionality: false,
      cookieConsentPerformance: false,
      cookieConsentTracking: false,
    });
  }, [handleCookieConsent]);
  const [hasCustomized, setHasCustomized] = useState(false);
  const handleBack = useCallback(() => setHasCustomized(false), []);
  const handleCustomize = useCallback(() => setHasCustomized(true), []);
  return isGAR || cookieConsent.cookieConsentBasic ? null : (
    <CookieConsent
      handleAcceptAll={handleAcceptAll}
      handleBack={handleBack}
      handleCustomizationSave={handleCookieConsent}
      handleCustomize={handleCustomize}
      handleAcceptEssential={handleAcceptEssential}
      hasCustomized={hasCustomized}
      {...props}
    />
  );
};

CookieConsentContainer.propTypes = {
  isGAR: PropTypes.bool.isRequired,
};

export default CookieConsentContainer;
