import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import imageWooflashLogo from 'assets/images/logo/logo.svg';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Menu, { MenuItem } from 'uikit/Menu';

import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const Header = ({
  className,
  handleMenuVisibleChange,
  isGAR,
  isMenuVisible,
  lang,
  t,
  urls,
}) => (
  <div className={cx('container', className)}>
    <Link className={cx('title')} to={t.homepage}>
      <img alt="" className={cx('title-image')} src={imageWooflashLogo} />
      <span className={cx('title-text')}>wooflash</span>
    </Link>
    {!isGAR && (
      <>
        <div
          className={cx('actions', {
            isVisible: isMenuVisible,
          })}
        >
          {/* Functionalities */}
          <Menu
            className={cx('action')}
            toggle={
              <button className={cx('resources', 'large')} type="button">
                {t.functionalities.label}
                <Icon className={cx('lang-icon')} name="chevron-down" />
              </button>
            }
          >
            {lang === 'fr' && (
              <MenuItem as={Link} href={t.functionalities.ai.link}>
                {t.functionalities.ai.label}
              </MenuItem>
            )}
            <MenuItem as={Link} href={t.functionalities.helpCenter.link}>
              {t.functionalities.helpCenter.label}
            </MenuItem>
            {lang === 'fr' && (
              <MenuItem as={Link} href={t.functionalities.useCases.link}>
                {t.functionalities.useCases.label}
              </MenuItem>
            )}
            {/* <MenuItem as={Link} href={t.functionalities.wooverse.link}>
              {t.functionalities.wooverse.label}
            </MenuItem> */}
          </Menu>
          {/* Persona */}
          <Menu
            className={cx('action')}
            toggle={
              <button className={cx('resources', 'large')} type="button">
                {t.persona.label}
                <Icon className={cx('lang-icon')} name="chevron-down" />
              </button>
            }
          >
            <MenuItem as={Link} href={t.persona.student.link}>
              {t.persona.student.label}
            </MenuItem>
            <MenuItem as={Link} href={t.persona.teacher.link}>
              {t.persona.teacher.label}
            </MenuItem>
            <MenuItem as={Link} href={t.persona.learningTechnologist.link}>
              {t.persona.learningTechnologist.label}
            </MenuItem>
            {lang === 'fr' && (
              <MenuItem as={Link} href={t.persona.k12.link}>
                {t.persona.k12.label}
              </MenuItem>
            )}
          </Menu>
          {/* Establishment */}
          {lang === 'en' && (
            <Button
              as="a"
              className={cx('action', 'establishments', 'large')}
              href={t.establishments.link}
              size="large"
            >
              {t.establishments.label}
            </Button>
          )}
          {lang === 'fr' && (
            <Menu
              className={cx('action')}
              toggle={
                <button className={cx('resources', 'large')} type="button">
                  {t.establishments.label}
                  <Icon className={cx('lang-icon')} name="chevron-down" />
                </button>
              }
            >
              <MenuItem as={Link} href={t.higherEducation.link}>
                {t.higherEducation.label}
              </MenuItem>
              <MenuItem as={Link} href={t.tne.link}>
                {t.tne.label}
              </MenuItem>
            </Menu>
          )}
          {/* Pricing */}
          <Button
            as="a"
            className={cx('action', 'pricing', 'large')}
            href={t.pricing.link}
            size="large"
          >
            {t.pricing.label}
          </Button>
          {/* Login */}
          <Button
            as="a"
            className={cx('action')}
            href={t.secondaryCTA.link}
            size="large"
          >
            {t.secondaryCTA.label}
          </Button>
          {/* Signup */}
          <Button
            as="a"
            className={cx('action')}
            href={t.primaryCTA.link}
            intent="primary"
            size="large"
          >
            {t.primaryCTA.label}
          </Button>

          <div className={cx('menu-mobile')}>
            <div className={cx('first-line')}>
              {/* Functionalities */}
              <Menu
                className={cx('action')}
                toggle={
                  <button className={cx('resources', 'small')} type="button">
                    {t.functionalities.label}{' '}
                    <Icon className={cx('lang-icon')} name="chevron-down" />
                  </button>
                }
              >
                {lang === 'fr' && (
                  <MenuItem as={Link} href={t.functionalities.ai.link}>
                    {t.functionalities.ai.label}
                  </MenuItem>
                )}
                <MenuItem as={Link} href={t.functionalities.helpCenter.link}>
                  {t.functionalities.helpCenter.label}
                </MenuItem>
                {lang === 'fr' && (
                  <MenuItem as={Link} href={t.functionalities.useCases.link}>
                    {t.functionalities.useCases.label}
                  </MenuItem>
                )}
                {/* <MenuItem as={Link} href={t.functionalities.wooverse.link}>
                  {t.functionalities.wooverse.label}
                </MenuItem> */}
              </Menu>
              {/* Persona */}
              <Menu
                className={cx('action')}
                toggle={
                  <button className={cx('resources', 'small')} type="button">
                    {t.persona.label}{' '}
                    <Icon className={cx('lang-icon')} name="chevron-down" />
                  </button>
                }
              >
                <MenuItem as={Link} href={t.persona.student.link}>
                  {t.persona.student.label}
                </MenuItem>
                <MenuItem as={Link} href={t.persona.teacher.link}>
                  {t.persona.teacher.label}
                </MenuItem>
                <MenuItem as={Link} href={t.persona.learningTechnologist.link}>
                  {t.persona.learningTechnologist.label}
                </MenuItem>
                {lang === 'fr' && (
                  <MenuItem as={Link} href={t.persona.k12.link}>
                    {t.persona.k12.label}
                  </MenuItem>
                )}
              </Menu>
              {/* Establishment */}
              {lang === 'en' && (
                <Link
                  className={cx('action', 'establishments', 'small')}
                  href={t.establishments.link}
                >
                  {t.establishments.label}
                </Link>
              )}
              {lang === 'fr' && (
                <Menu
                  className={cx('action')}
                  toggle={
                    <button className={cx('resources', 'small')} type="button">
                      {t.establishments.label}{' '}
                      <Icon className={cx('lang-icon')} name="chevron-down" />
                    </button>
                  }
                >
                  <MenuItem as={Link} href={t.establishments.link}>
                    {t.establishments.label}
                  </MenuItem>
                  <MenuItem as={Link} href={t.higherEducation.link}>
                    {t.higherEducation.label}
                  </MenuItem>
                </Menu>
              )}
            </div>
            <div className={cx('second-line')}>
              <Link
                className={cx('action', 'pricing', 'small')}
                to={t.pricing.link}
              >
                {t.pricing.label}
              </Link>
              <Menu
                className={cx('action')}
                toggle={
                  <button className={cx('lang')} type="button">
                    {lang}
                    <Icon className={cx('lang-icon')} name="chevron-down" />
                  </button>
                }
              >
                {Object.entries(urls).map(([l, url]) => (
                  <MenuItem as={Link} href={url} key={l}>
                    {l.toUpperCase()}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        <button
          className={cx('mobile')}
          onClick={handleMenuVisibleChange}
          type="button"
        >
          <Icon name="bars" />
        </button>
      </>
    )}
  </div>
);
Header.propTypes = {
  className: PropTypes.string,
  handleMenuVisibleChange: PropTypes.func.isRequired,
  isGAR: PropTypes.bool.isRequired,
  isMenuVisible: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  urls: PropTypes.object.isRequired,
};

Header.defaultProps = {
  className: undefined,
};

export default Header;
