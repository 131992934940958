import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Header from './Header';

const HeaderContainer = (props) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const handleMenuVisibleChange = useCallback(() => {
    setMenuVisible((v) => !v);
  }, []);
  return (
    <Header
      handleMenuVisibleChange={handleMenuVisibleChange}
      isMenuVisible={isMenuVisible}
      {...props}
    />
  );
};

HeaderContainer.propTypes = {
  urls: PropTypes.object.isRequired,
};

export default HeaderContainer;
