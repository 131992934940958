import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Switch.module.scss';

const cx = classNames.bind(styles);

const Switch = React.forwardRef(
  ({ children, className, isDisabled, label, name, ...props }, ref) => {
    const [{ value, ...field }, meta] = useField(name);
    return (
      <label
        className={cx(
          'container',
          {
            isDisabled,
            hasError: meta.touched && meta.error,
          },
          className,
        )}
        htmlFor={name}
      >
        <input
          aria-labelledby={`switch-${name}-label`}
          checked={value}
          className={cx('field')}
          disabled={isDisabled}
          id={name}
          ref={ref}
          type="checkbox"
          value={!value}
          {...field}
          {...props}
        />
        <div
          className={cx('slider', {
            isChecked: value,
          })}
        />
        <div className={cx('content')} id={`switch-${name}-label`}>
          {label && <p className={cx('label')}>{label}</p>}
          {children && <div className={cx('children')}>{children}</div>}
          {meta.touched && meta.error && (
            <p
              className={cx('error-message')}
              id={`error-${name}`}
              role="alert"
            >
              {meta.error}
            </p>
          )}
        </div>
      </label>
    );
  },
);

Switch.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
};

Switch.defaultProps = {
  children: undefined,
  className: undefined,
  isDisabled: false,
  label: undefined,
};

export default Switch;
