import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

const HelpTooltip = ({ className, tooltip }) => (
  <Tooltip tooltip={tooltip}>
    <Icon className={className} name="info-circle" />
  </Tooltip>
);

HelpTooltip.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
};

HelpTooltip.defaultProps = {
  className: undefined,
};

export default HelpTooltip;
