import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import { NotificationsContainer } from 'uikit/Notifications';

import imageShareEN from './images/shareEN.jpg';
import imageShareFR from './images/shareFR.jpg';
import CookieConsent from './CookieConsent';
import Footer from './Footer';
import Header from './Header';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

const Layout = ({ children, isGAR, lang, t, urls }) => (
  <>
    <Helmet>
      <title>Wooflash</title>
      <meta name="author" content="Wooflash" />
      <meta name="description" content={t.openGraph.description} />
      <meta name="keywords" content={t.openGraph.keywords} />
      <meta property="og:title" content="Wooflash" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.wooflash.com" />
      <meta property="og:description" content={t.openGraph.description} />
      <meta
        property="og:image"
        content={`https://www.wooflash.com${
          lang === 'fr' ? imageShareFR : imageShareEN
        }`}
      />
      <link rel="alternate" hrefLang="en" href="https://www.wooflash.com/" />
      <link rel="alternate" hrefLang="fr" href="https://www.wooflash.com/fr/" />
    </Helmet>
    <Header isGAR={isGAR} lang={lang} t={t.header} urls={urls} />
    <div className={cx('header-spacer')} />
    {children}
    <Footer isGAR={isGAR} t={t.footer} />
    <CookieConsent isGAR={isGAR} t={t.cookies} />
    <NotificationsContainer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isGAR: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  urls: PropTypes.object.isRequired,
};

Layout.defaultProps = {
  isGAR: false,
};

export default Layout;
