import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'uikit/Button';
import Icon from 'uikit/Icon';

import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = ({ className, isGAR, t }) => (
  <footer className={cx('container', className)}>
    <div className={cx('left')}>
      <Link className={cx('title')} to="/">
        wooflash
      </Link>
      <div className={cx('subtitle')}>
        By <a href="https://www.wooclap.com">wooclap</a>
      </div>
      {!isGAR && (
        <p>
          <Button
            as="a"
            className={cx('social')}
            href="https://twitter.com/wooflashapp"
            icon={<Icon name="twitter" variant="brand" />}
            intent="outline"
          />
          <Button
            as="a"
            className={cx('social')}
            href="https://www.linkedin.com/company/28550250"
            icon={<Icon name="linkedin-in" variant="brand" />}
            intent="outline"
          />
          <Button
            as="a"
            className={cx('social')}
            href=" https://www.youtube.com/channel/UCFvL-2dCKyAXL7hON_LSmUw"
            icon={<Icon name="youtube" variant="brand" />}
            intent="outline"
          />
          <Button
            as="a"
            className={cx('social')}
            href="mailto:community@wooflash.com"
            icon={<Icon name="envelope" />}
            intent="outline"
          />
        </p>
      )}
    </div>
    {!isGAR && (
      <div className={cx('right')}>
        <div className={cx('filler')} />
        {/* <div className={cx('footer-section')}>
        <h2 className={cx('footer-section-title')}>{t.column1Title}</h2>
        <Link className={cx('footer-section-link')} to={t.column1Item1.link}>
          {t.column1Item1.label}
        </Link>
        <Link className={cx('footer-section-link')} to={t.column1Item2.link}>
          {t.column1Item2.label}
        </Link>
        <Link className={cx('footer-section-link')} to={t.column1Item3.link}>
          {t.column1Item3.label}
        </Link>
        <Link className={cx('footer-section-link')} to={t.column1Item4.link}>
          {t.column1Item4.label}
        </Link>
      </div> */}
        <div className={cx('footer-section')}>
          <h2 className={cx('footer-section-title')}>{t.column2Title}</h2>
          <Link className={cx('footer-section-link')} to={t.column2Item1.link}>
            {t.column2Item1.label}
          </Link>
          <a className={cx('footer-section-link')} href={t.column2Item2.link}>
            {t.column2Item2.label}
          </a>
          <a className={cx('footer-section-link')} href={t.column2Item3.link}>
            {t.column2Item3.label}
          </a>
          <a className={cx('footer-section-link')} href={t.column2Item4.link}>
            {t.column2Item4.label}
          </a>
          {t.column2Item5 && (
            <a className={cx('footer-section-link')} href={t.column2Item5.link}>
              {t.column2Item5.label}
            </a>
          )}
        </div>
        <div className={cx('footer-section')}>
          <h2 className={cx('footer-section-title')}>{t.column3Title}</h2>
          <Link className={cx('footer-section-link')} to={t.column3Item1.link}>
            {t.column3Item1.label}
          </Link>
          <Link className={cx('footer-section-link')} to={t.column3Item2.link}>
            {t.column3Item2.label}
          </Link>
          <a
            className={cx('footer-section-link')}
            target="_blank"
            href={t.column3Item3.link}
            rel="noreferrer"
          >
            {t.column3Item3.label}
          </a>
          <a className={cx('footer-section-link')} href={t.column3Item4.link}>
            {t.column3Item4.label}
          </a>
        </div>
      </div>
    )}
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
  isGAR: PropTypes.bool.isRequired,
  t: PropTypes.object.isRequired,
};

Footer.defaultProps = {
  className: undefined,
};

export default Footer;
