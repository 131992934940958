import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';
import styles from './Button.module.scss';

const cx = classNames.bind(styles);

const Button = React.forwardRef(
  (
    {
      as: Component,
      children,
      className,
      icon,
      intent,
      isDisabled,
      isLoading,
      size,
      ...props
    },
    ref,
  ) => {
    const additionalProps = {};
    if (Component === 'button') {
      additionalProps.disabled = isDisabled;
      additionalProps.type = 'button';
    }
    return (
      <Component
        className={cx(
          'container',
          `intent-${intent}`,
          `size-${size}`,
          {
            hasIcon: !!icon,
            isDisabled,
            isEmpty: icon && !children,
            isLoading,
          },
          className,
        )}
        ref={ref}
        {...additionalProps}
        {...props}
      >
        {isLoading && (
          <span className={cx('spinner')}>
            <Icon name="spinner-third" spin />
          </span>
        )}
        {icon && (
          <p className={cx('icon')}>
            {typeof icon === 'string' ? <Icon name={icon} /> : icon}
          </p>
        )}
        {children && <span className={cx('content')}>{children}</span>}
      </Component>
    );
  },
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  intent: PropTypes.oneOf([
    'default',
    'primary',
    'white',
    'outline',
    'danger',
    'wrong',
    'correct',
  ]),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'small', 'large']),
};

Button.defaultProps = {
  as: 'button',
  children: undefined,
  className: undefined,
  icon: undefined,
  intent: 'default',
  isDisabled: false,
  isLoading: false,
  size: 'default',
};

export default Button;
