import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'uikit/Icon';

import styles from './MenuItem.module.scss';

const cx = classNames.bind(styles);

const MenuItem = React.forwardRef(
  (
    {
      as: Component,
      children,
      className,
      htmlType,
      icon,
      isActive,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    const additionalProps = {};
    if (Component === 'button') {
      additionalProps.disabled = isDisabled;
      additionalProps.type = htmlType;
    }
    return (
      <Component
        className={cx(
          'container',
          {
            isActive,
          },
          className,
        )}
        ref={ref}
        role="menuitem"
        {...additionalProps}
        {...props}
      >
        {icon && <Icon className={cx('icon')} fixedWidth name={icon} />}
        <span>{children}</span>
      </Component>
    );
  },
);

MenuItem.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  as: 'button',
  children: undefined,
  className: undefined,
  htmlType: 'button',
  icon: undefined,
  isActive: false,
  isDisabled: false,
};

export default MenuItem;
